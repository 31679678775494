import React from "react";
import { useTheme } from "@mui/material/styles";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function AboutMe() {
  const theme = useTheme();

  // Dynamically set the gradient based on the theme mode
  const boldGreenStyle = {
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(to right, #2ecc71, #1CD5C8)"
        : "linear-gradient(to right, #2AB9CC, #2BBF7F)",
  };

  return (
    <div className="container">
      <div className="row">
        <div className="about-me-text">
          <h1
            className="block"
            style={{
              color: theme.palette.text.primary,
              fontSize: "5rem",
              fontWeight: 350,
              marginBottom: "-10px",
            }}
          >
            <span>Hi! I'm </span>
            <span className="bold-green" style={boldGreenStyle}>
              Sebastian
            </span>
          </h1>
          <h2
            className="block"
            style={{
              color: theme.palette.text.primary,
              fontSize: "2.25rem",
              fontWeight: 325,
            }}
          >
            I am a{" "}
            <span className="bold-green" style={boldGreenStyle}>
              Fullstack Software Developer
            </span>{" "}
            who is most passionate about{" "}
            <span className="bold-green" style={boldGreenStyle}>
              .NET
            </span>{" "}
            and{" "}
            <span className="bold-green" style={boldGreenStyle}>
              React
            </span>
            , located in Hamburg, Germany.
          </h2>
          <h3
            className="block"
            style={{
              color: theme.palette.text.primary,
              fontSize: "1.5rem",
              fontWeight: 325,
            }}
          >
            Throughout my career I've worked on various projects, including
            backend systems, web apps, games, mobile applications and more.
            Additionally, I've handled databases and cloud environments and
            gained initial exposure to software architecture, consulting and
            design.
          </h3>
          <h3
            className="block"
            style={{
              color: theme.palette.text.primary,
              fontSize: "1.5rem",
              fontWeight: 325,
            }}
          >
            Want to get to know me better? Let's connect!
          </h3>
          <div className="social-icons block">
            <a
              href="https://www.linkedin.com/in/sebastian-bugl-25841b237/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin style={{ color: theme.palette.text.primary }} />
            </a>
            <a
              href="https://github.com/Sleepboop"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub style={{ color: theme.palette.text.primary }} />
            </a>
            <a href="mailto:sebastian.bugl@outlook.com">
              <MdEmail style={{ color: theme.palette.text.primary }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
