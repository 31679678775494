import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import "./App.css";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import Experience from "./Experience";
import Projects from "./Projects";
import AboutMe from "./AboutMe";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#F4F7F9",
    },
    text: {
      primary: "#000000",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#141619",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
});

const openResume = () => {
  window.open(
    "https://drive.google.com/file/d/1Rq2fQsRZ19TnAxqUUQWWlp9kV49Q02Eo/view?usp=sharing",
    "_blank"
  );
};

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("theme");
    return savedMode ? JSON.parse(savedMode) : true;
  });

  const toggleTheme = () => {
    setIsDarkMode((prevIsDarkMode: any) => {
      const newMode = !prevIsDarkMode;
      localStorage.setItem("theme", JSON.stringify(newMode));
      document.body.classList.add("transition");
      setTimeout(() => {
        document.body.classList.remove("transition");
      }, 400);
      return newMode;
    });
  };

  useEffect(() => {
    document.body.style.backgroundColor = isDarkMode
      ? darkTheme.palette.background.default
      : lightTheme.palette.background.default;
  }, [isDarkMode]);

  useEffect(() => {
    const textBlocks = document.querySelectorAll(".text-block");
    textBlocks.forEach((block, index) => {
      const delay = index * 200;
      (block as HTMLElement).style.animationDelay = `${delay}ms`;
    });
  }, []);

  const selectedTheme = isDarkMode ? darkTheme : lightTheme;

  useEffect(() => {
    const navbar = document.querySelector(".navbar-container") as HTMLElement;

    const handleScroll = () => {
      if (window.pageYOffset === 0) {
        // At the top of the page
        navbar.style.top = "0"; // Show the navbar
      } else {
        // Not at the top of the page
        navbar.style.top = "-60px"; // Hide the navbar
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ThemeProvider theme={selectedTheme}>
      <Router>
        <div className="page-container">
          <div className="navbar-container">
            <div
              className="navbar"
              style={{ color: selectedTheme.palette.text.primary }}
            >
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "navbarText navbarTextActive" : "navbarText"
                }
                style={{
                  color: selectedTheme.palette.text.primary, // Set color through inline style
                }}
              >
                About&nbsp;me
              </NavLink>
              <NavLink
                to="/experience"
                className={({ isActive }) =>
                  isActive ? "navbarText navbarTextActive" : "navbarText"
                }
                style={{
                  color: selectedTheme.palette.text.primary, // Set color through inline style
                }}
              >
                Experience
              </NavLink>
              <NavLink
                to="/projects"
                className={({ isActive }) =>
                  isActive ? "navbarText navbarTextActive" : "navbarText"
                }
                style={{
                  color: selectedTheme.palette.text.primary, // Set color through inline style
                }}
              >
                Projects
              </NavLink>
              <span className="navbarText" onClick={openResume}>
                Resume
              </span>
              <div>
                <DarkModeSwitch
                  className="darkModeSwitch"
                  checked={isDarkMode}
                  onChange={toggleTheme}
                  size={25}
                />
              </div>
            </div>
          </div>
          <Routes>
            <Route path="/" element={<AboutMe />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/projects" element={<Projects />} />
          </Routes>
          <div
            className="container"
            style={{
              marginTop: "auto",
              width: "100%",
              maxWidth: "990px",
              padding: "0 20px",
            }}
          >
            <footer
              className="footer"
              style={{
                color: selectedTheme.palette.text.primary,
                width: "100%",
              }}
            >
              © {new Date().getFullYear()} Sebastian Bugl - All rights reserved. Made with React and{" "}
              {isDarkMode ? "🤍" : "🖤"}.{!isDarkMode && " MY EYES!"}
            </footer>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
