import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { Card, CardContent, Typography, List, ListItem } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import { styled } from "@mui/system";

function Experience() {
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === "dark";

  const getIcon = (type: string) => {
    switch (type) {
      case "work":
        return <WorkIcon />;
      case "education":
        return <SchoolIcon />;
      case "personal":
        return <PersonIcon />;
      default:
        return <PersonIcon />;
    }
  };

  const createListFromText = (text: string) => (
    <List dense>
      {text.split("-").map((item, index) =>
        item.trim() ? (
          <ListItem key={index} className="experience-list-item">
            {item.trim()}
          </ListItem>
        ) : null
      )}
    </List>
  );

  // Function to get the timeline dot color based on theme mode
  const GradientTimelineDot = styled(TimelineDot)(({ theme }) => ({
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(to right, #2ecc71, #1CD5C8)"
        : "linear-gradient(to right, #2AB9CC, #2BBF7F)",
    backgroundSize: "110% 110%", // Enlarge the background size
    backgroundPosition: "center", // Ensure the gradient is centered
  }));

  return (
    <>
      <div
        className="timeline-container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Timeline
          className="block"
          style={{
            marginTop: "-20px",
          }}
        >
          <TimelineItem>
            <TimelineOppositeContent
              style={{ flex: 0.3, color: theme.palette.text.secondary }}
            >
              <Typography variant="body2">2024 - now</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineSeparator>
                <GradientTimelineDot>{getIcon("work")}</GradientTimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ flex: 0.7 }}>
              <Card
                className="card block"
                style={{
                  backgroundColor: `rgba(${
                    isDarkMode ? "0, 0, 0" : "255, 255, 255"
                  }, 0.8)`,
                }}
              >
                <CardContent className="card-content">
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.text.primary }}
                  >
                    .NET Developer / Consultant
                  </Typography>
                  <Typography
                    className="subtitle"
                    variant="subtitle1"
                    gutterBottom
                    style={{ color: theme.palette.text.secondary }}
                  >
                    Fulltime employment specializing in .NET Development and
                    Consulting.
                  </Typography>
                  {createListFromText(`-.NET Core backend development, architecture design, and consulting of software to connect disparate
business systems and allows for seamless data flow. Design and implementation of APIs to enhance
system interoperability.
- General .NET / C# backend development.
                  -Infrastructure deployments and setup of Azure Cloud environments.
                  -Consulting and advisory services to clients on the aforementioned tasks.`)}
                </CardContent>
              </Card>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              style={{ flex: 0.3, color: theme.palette.text.secondary }}
            >
              <Typography variant="body2">2021-2024</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <GradientTimelineDot>{getIcon("education")}</GradientTimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ flex: 0.7 }}>
              <Card
                className="card block"
                style={{
                  backgroundColor: `rgba(${
                    isDarkMode ? "0, 0, 0" : "255, 255, 255"
                  }, 0.8)`,
                }}
              >
                <CardContent className="card-content">
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Fullstack Software Developer
                  </Typography>
                  <Typography
                    className="subtitle"
                    variant="subtitle1"
                    gutterBottom
                    style={{ color: theme.palette.text.secondary }}
                  >
                    Participated in a comprehensive program combining practical
                    training with academic coursework in software development.
                  </Typography>
                  <Typography
                    className="subtitle2"
                    variant="subtitle1"
                    gutterBottom
                    style={{
                      color: theme.palette.text.secondary,
                      marginTop: theme.spacing(2),
                    }}
                  >
                    Practical (employment)
                  </Typography>
                  {createListFromText(`-Fullstack development and maintenance of a Java based web server and its associated databases.
          -Independent development, architecture and design of a React Native App with TypeScript and Firebase
as BaaS(Backend as a Service).`)}
                  {/* Academic Experience */}
                  <Typography
                    className="subtitle2"
                    variant="subtitle1"
                    gutterBottom
                    style={{
                      color: theme.palette.text.secondary,
                      marginTop: theme.spacing(2),
                    }}
                  >
                    Academic (classes)
                  </Typography>
                  {createListFromText(`-Acquired knowledge of Computer Science, Programming languages, Networks, Software development
methodologies, Databases and SQL, Web development, Software testing, Software architecture and more.`)}
                </CardContent>
              </Card>
            </TimelineContent>
          </TimelineItem>

          {/* Timeline Item 3 */}
          <TimelineItem>
            <TimelineOppositeContent
              style={{ flex: 0.3, color: theme.palette.text.secondary }}
            >
              <Typography variant="body2">Since 2019</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <GradientTimelineDot>{getIcon("personal")}</GradientTimelineDot>
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent style={{ flex: 0.7 }}>
              <Card
                className="card block"
                style={{
                  backgroundColor: `rgba(${
                    isDarkMode ? "0, 0, 0" : "255, 255, 255"
                  }, 0.8)`,
                  marginBottom: "-70px",
                }}
              >
                <CardContent className="card-content">
                  <Typography
                    variant="h5"
                    style={{ color: theme.palette.text.primary }}
                  >
                    Fullstack Software Developer
                  </Typography>
                  <Typography
                    className="subtitle"
                    variant="subtitle1"
                    gutterBottom
                    style={{ color: theme.palette.text.secondary }}
                  >
                    From first discovering my passion until years later today, I
                    have always worked on personal projects.
                  </Typography>
                  {createListFromText(
                    `-Fullstack applications with ASP.NET, C#, React and TypeScript
                  -Games with Unity and C# / Godot and GDScript
                  -Desktop applications with WPF and C#
                  -Console applications with C#
                  -Webapplications with React and TypeScript
                  -Mobile apps with React Native / Xamarin
                  -And many more!`
                  )}
                </CardContent>
              </Card>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
    </>
  );
}

export default Experience;
