import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardActionArea,
  Grid,
  CardContent,
  Typography,
  CardMedia,
  Chip,
} from "@mui/material";
import unityImage from "./unity.jpg";
import reactImage from "./react.jpg";
import devImage from "./dev.jpg";

function Projects() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark"; // Determine if it's dark mode

  const chipGradient = isDarkMode
    ? "linear-gradient(to right, #2ecc71, #1CD5C8)"
    : "linear-gradient(to right, #2AB9CC, #2BBF7F)";

  const chipStyle = {
    background: chipGradient,
    color: isDarkMode ? "black" : "white",
    margin: "10px",
  };

  return (
    <div className="container">
      <Grid container spacing={4} direction="column">
        <Grid item xs={12}>
          <Card
            className="block"
            style={{
              backgroundColor: `rgba(${
                isDarkMode ? "0, 0, 0" : "255, 255, 255"
              }, 0.8)`, // Card background color
              marginBottom: "20px",
            }}
          >
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=1K9TvjrLC9M&ab_channel=SebastianBugl",
                  "_blank"
                )
              }
            >
              <Grid container>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      React Native Mobile App
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      A mobile app developed for trainees, working students,
                      etc. to exchange information, files and more.
                    </Typography>
                  </CardContent>
                  <div
                    style={{
                      display: "flex",
                      padding: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Chip label="React Native" style={chipStyle} />
                    <Chip label="TypeScript" style={chipStyle} />
                    <Chip label="Firebase" style={chipStyle} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <CardMedia
                    component="img"
                    image={reactImage}
                    alt="AzubiConnect"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
          <Card
            className="block"
            style={{
              backgroundColor: `rgba(${
                isDarkMode ? "0, 0, 0" : "255, 255, 255"
              }, 0.8)`,
              marginBottom: "20px",
            }}
          >
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://pixelbrothers.studio/",
                  "_blank"
                )
              }
            >
              <Grid container>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Game Jams
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Some games developed with friends for so called "Game Jams", where you have a limited amount of time (typically a weekend) to develop a game with a certain theme.
                    </Typography>
                  </CardContent>
                  <div
                    style={{
                      display: "flex",
                      padding: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Chip label="Unity" style={chipStyle} />
                    <Chip label="C#" style={chipStyle} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <CardMedia
                    component="img"
                    image={unityImage}
                    alt="Granny Goes Deep"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>

          <Card
            className=" project-card block"
            style={{
              backgroundColor: `rgba(${
                isDarkMode ? "0, 0, 0" : "255, 255, 255"
              }, 0.8)`, // Card background color
              marginBottom: "40px",
            }}
          >
            <CardActionArea>
              <Grid container>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      And more to come!
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      I am still working on many more projects to show in my
                      portfolio.
                    </Typography>
                  </CardContent>
                  <div
                    style={{
                      display: "flex",
                      padding: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Chip label="ASP.NET" style={chipStyle} />
                    <Chip label="C#" style={chipStyle} />
                    <Chip label="React" style={chipStyle} />
                    <Chip label="TypeScript" style={chipStyle} />
                    <Chip label="..." style={chipStyle} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <CardMedia
                    component="img"
                    image={devImage}
                    alt="More"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Projects;
